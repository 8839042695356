<template>
    <section>
        <cargando v-if="cargando" />
        <div v-for="(data,z) in pedidos" :key="z" class="row mx-0 mb-5">
            <p class="col pl-5 text-general f-18 f-500">
                Entregas programadas para el: {{ data.fecha | helper-fecha('dddd DD MMMM YYYY') }}
            </p>
            <div v-for="(info,x) in data.items" :key="x" class="col-12 px-0 bg-white br-8 mt-3">
                <div class="row mx-0 py-3">
                    <p class="col-auto f-18 f-500 text-general">
                        {{ determinarHorario(info.horario) }}
                    </p>
                    <div v-if="bandera === 'asignados'" class="btn-general f-14 px-3 mr-3 ml-auto" @click="finalizarPedido(info.items)">
                        Finalizar Pedido
                    </div>
                    <div v-else class="btn-general f-14 px-3 mr-3 ml-auto" @click="irAsignar(data.fecha,determinarHorario(info.horario),info.items)">
                        Asignar
                    </div>
                    <div class="col-3">
                        <el-input v-model="info.buscador" placeholder="Buscar" class="br-20 w-100 ml-auto" size="small" />
                    </div>
                </div>
                <el-table
                :data="info.items.filter(data => !info.buscador || data.leechero.toLowerCase().includes(info.buscador.toLowerCase()))"
                header-row-class-name="text-general f-16 f-500"
                style="width: 100%"
                @row-click="verDetalle"
                >
                    <el-table-column
                    fixed
                    class-name="text-center"
                    width="150"
                    >
                        <template slot-scope="scope">
                            <div class="row d-middle-center">
                                <div v-if="bandera === 'asignados'" class="px-0">
                                    <el-tooltip v-if="scope.row.total_entregas > 0" placement="bottom" content="En entrega" effect="light">
                                        <i class="icon-truck f-20 text-green mx-2" />
                                    </el-tooltip>
                                </div>
                                <div class="px-0">
                                    <el-tooltip v-if="scope.row.nuevo_intento > 1" placement="bottom" content="Nuevo intento de entrega" effect="light">
                                        <i class="icon-history f-20 text-general mx-2" />
                                    </el-tooltip>
                                </div>
                                <div class="px-0">
                                    <el-tooltip v-if="scope.row.productos_faltantes" placement="bottom" content="Productos faltantes" effect="light">
                                        <i class="icon-package-variant f-20 text-general-red mx-2" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed prop="id" label="Pedido" width="100" />

                    <el-table-column
                    label="Leechero"
                    fixed
                    width="180"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ scope.row.leechero }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="cliente"
                    label="Cliente"
                    class-name="text-center"
                    min-width="180"
                    />
                    <el-table-column
                    prop="cantidad_productos"
                    label="Productos"
                    min-width="120"
                    class-name="text-center"
                    />
                    <el-table-column
                    label="Valor"
                    min-width="100"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ separadorNumero(scope.row.valor_final_pedido) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="vehiculo_placa"
                    label="Vehículo"
                    min-width="100"
                    />
                    <el-table-column
                    prop="vehiculo_conductor"
                    label="Conductor"
                    min-width="120"
                    />
                    <el-table-column
                    prop="estado_leeche"
                    label="Estado Leeche"
                    class-name="text-center"
                    width="180"
                    />
                    <el-table-column
                    prop="estado_logistica"
                    label="Estado logistica"
                    class-name="text-center"
                    width="180"
                    />
                    <el-table-column
                    prop="total_peso"
                    label="Peso (Kg)"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="total_volumen"
                    label="Volumen (m3)"
                    class-name="text-center"
                    />
                </el-table>
            </div>
        </div>
        <!-- Partials -->
        <modal-finalizar-pedidos ref="modalFinalizarPedidos" @update="getPedidosAsignados" />
        <modal-asignar-operador ref="modalAsignarOperador" :envios="envios_select" @consultar="getPedidosAsignados" />
        <modal-detalle-pedido ref="modalDetalle" @update="getPedidosAsignados" />
    </section>
</template>

<script>
import Asignados from "~/services/pedidos/pedidos-routing";

export default {
    components: {
        modalFinalizarPedidos: () => import('../partials/modalFinalizarPedidos'),
        modalAsignarOperador: () =>import('../partials/modalAsignarOperador'),
        modalDetallePedido: () => import('../partials/modalDetallesRuta'),
    },
    props: {
        bandera: {
            type: String, required: true,
        },
        filtro: {
            type: Object,
            default: () => ({})
        },
        actualizar: {
            type: Boolean,
            default: () => false
        },
    },
    data(){
        return {
            cargando: true,
            pedidos: [],
            envios_select: {},
            buscador: '',
        }
    },
    watch: {
        filtro: {
            deep: true,
            async handler(val){
                await this.validar()
            }
        },
        actualizar: {
            deep: true,
            async handler(val){
                await this.getPedidosAsignados()
            }
        },
    },
    created(){
        this.validar();
    },
    methods: {
        async validar(){
            if (this.id_moneda === null){
                setTimeout(async() => {
                    this.getPedidosAsignados();
                },1000);
            } else {
                this.getPedidosAsignados();
            }
        },
        async getPedidosAsignados(){
            this.cargando = true;
            let params = {
                bandera: this.bandera,
                filtro_estado: this.filtro.estado_leeche,
                filtro_leechero: this.filtro.leechero,
                filtro_conductor: this.filtro.conductor,
                filtro_vehiculo: this.filtro.vehiculo,
                filtro_logistico: this.filtro.estado_logistico,
                filtro_valor_desde: this.filtro.valor_desde,
                filtro_valor_hasta: this.filtro.valor_hasta,
                filtro_productos_desde: this.filtro.productos_desde,
                filtro_productos_hasta: this.filtro.productos_hasta,
                filtro_peso_desde: this.filtro.peso_desde,
                filtro_peso_hasta: this.filtro.peso_hasta,
                filtro_volumen_desde: this.filtro.volumen_desde,
                filtro_volumen_hasta: this.filtro.volumen_hasta,
            };
            try {
                const { data } = await Asignados.getPedidosAsignados(params)
                this.pedidos = data.pedidos;
                this.cargando = false;
                let pedidosFechas = {};
                pedidosFechas.pedidos = data.id_pedidos;
                pedidosFechas.fechas = data.fechas;
                this.$store.commit('routing/tablero/setPedidosFechas', pedidosFechas)
            } catch (error){
                this.error_catch(error)
            }
        },
        determinarHorario(hora){
            if (hora < 12){
                return "Desde las "+hora+":00 a.m";
            }
            if (hora >= 12){
                return "Desde las "+hora+":00 p.m";
            }
            return "";
        },
        irAsignar(fecha,horario,envios){
            const info_envios = new Object();
            info_envios.fecha = fecha;
            info_envios.horario = horario;
            info_envios.items = envios;
            this.envios_select = info_envios;
            this.$refs.modalAsignarOperador.toggle();
        },
        finalizarPedido(pedidos){
            this.$refs.modalFinalizarPedidos.toggle(pedidos);
        },
        verDetalle(item){
            this.$refs.modalDetalle.toggle(item.id);
        }
    }
}
</script>
